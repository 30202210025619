import React, {useEffect} from 'react';
import "./HelpOverview.css"
import {Col, Collapse, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import HelpTextura from "./HelpTextura";
import {PlusOutlined} from "@ant-design/icons";

const {Panel} = Collapse;

function HelpOnlineStore(props) {
    return (<div>
        <Layout>
            <Content style={{backgroundColor: '#F0F0F0', fontFamily: "Proxima Nova, sans-serif"}}>
                <div className="help-center-top-section">
                    <div className="help-center-text-content">
                        <h2 style={{fontSize: '1.6rem', fontWeight: 700, lineHeight: 'normal'}}>Vieunite Home
                            Support</h2>
                        <h3 style={{fontSize: '2.9rem', fontWeight: 700, lineHeight: 'normal'}}>How Can We
                            Help?</h3>
                    </div>
                </div>
                <p style={{
                    width: '100%',
                    color: '#6A6A6A',
                    fontSize: '1rem',
                    fontWeight: '400',
                    paddingTop: '1rem',
                    paddingLeft: '8vw'
                }}>
                    <a href={'/help-center'} style={{cursor: 'pointer', color: '#6A6A6A'}}>Support</a> / Online Store
                </p>

                <Row justify={"center"}
                     style={{
                         // backgroundColor: '#f4f4f4',
                     }}>
                    <Col span={20} lg={20} md={20}>
                        <Collapse expandIconPosition={"end"}
                                  bordered={false}
                                  style={{
                                      backgroundColor: '#f0f0f0', color: '#f4f4f4', border: "none"
                                  }}
                                  expandIcon={() => <PlusOutlined style={{
                                      fontSize: '24px', fontWeight: "900",
                                  }}/>}
                        >
                            <Panel header="How do I place an order? "
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="1">
                                <p>To place an order, simply visit our website, select the your Textura digital canvas then
                                    click "Add to Cart" to proceed to checkout. Then follow the on-screen instructions
                                    to complete your purchase.</p>
                            </Panel>
                            <Panel header="What payment methods do you accept? "
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="2">
                                <p>We accept a variety of payment methods, including: Credit/Debit Cards (Visa, MasterCard,
                                    American Express, Discover)</p>
                            </Panel>
                            <Panel header="Is my payment information secure?  "
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="3">
                                <p>Yes, your payment information is secure. We use industry-standard encryption to protect
                                    your personal and financial information during the transaction process.</p>
                            </Panel>
                            <Panel header="Can I change or cancel my order after it has been placed?"
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="4">
                                <p>Once an order has been placed, we start processing it immediately to ensure timely
                                    delivery. </p>
                                <p>If you need to change or cancel your order, please contact our customer service team
                                    on <a href="mailto:info@vieunite.com">info@vieunite.com </a> as soon as possible. </p>
                                <p>While we will do our best to accommodate your request, we cannot guarantee changes or
                                    cancellations after the order has been processed.</p>
                            </Panel>
                            <Panel header="Do you offer gift cards or discount codes?"
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="5">
                                <p>Yes, we occasionally provide discount codes. To stay updated on our latest promotions
                                    and discount codes, <a href="https://linktr.ee/vieunite">follow
                                        us on social media</a>.</p>
                            </Panel>
                            <Panel header="How can I track my order?"
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="6">
                                <p>After your order has been shipped, you will receive a confirmation email with a courier
                                    tracking number. You can use this tracking number on the courier's website to track
                                    the status of your shipment.</p>
                            </Panel>
                            <Panel header="What should I do if I encounter an issue with my order? "
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="7">
                                <p>If you encounter any issues with your order, such as receiving the wrong item or a
                                    damaged product, please contact our customer service team immediately by emailing
                                    <a href="mailto:support@vieunite.com">support@vieunite.com </a>. Provide your order number
                                    and a description of the issue, and we will work to resolve it as quickly as possible.</p>
                            </Panel>
                            <Panel header="Do you offer international shipping?"
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="8">
                                <p>Yes, we offer international shipping to many countries. Shipping rates and delivery
                                    times vary based on the destination. At the checkout, you can enter your shipping
                                    address to view available options and costs.</p>
                            </Panel>
                            <Panel header="What are your shipping rates and delivery times? "
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="9">
                                <p>Shipping rates and delivery times depend on your location and the shipping method selected
                                    at checkout. Standard shipping typically takes 5-7 business days within UK.
                                    International shipping times vary. Expedited shipping options are also available on request.</p>
                            </Panel>
                            <Panel header="Can I return or exchange my purchase? "
                                   style={{
                                       fontSize: '24px', fontWeight: "700",
                                   }}
                                   key="10">
                                <p>We want you to be completely satisfied with your purchase, if for any reason you are not,
                                    you may return or exchange your canvas within 30 days of receipt. Please review our
                                    <a href="https://vieunite.com/terms-and-conditions#RETURNS"> Return Policy </a> for
                                    detailed instructions on how to proceed with a return or exchange.</p>
                            </Panel>

                        </Collapse>
                    </Col>
                </Row>

                <Row justify={"center"}
                     style={{
                         backgroundColor: 'black',
                         margin: "0 8vw 4vw 8vw",
                         borderRadius: "15px",

                     }}
                >
                    <div style={{padding: "3rem", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2 className="title-app m-text"
                            style={{
                                color: 'white',
                                display: "flex",
                                justifyContent: "center",
                                margin: '0 1rem',
                            }}>
                            Request Support
                        </h2>
                        <p className="text-content-app m-text"
                           style={{
                               color: 'white',
                               textAlign: "center",
                               paddingLeft: '8rem',
                               paddingRight: '8rem',
                               // paddingTop: "1rem",
                               // paddingBottom: "1rem",
                               // margin: '0 1rem',
                           }}>
                            If you have a question that is not answered here, or you are having any issues with using your
                            Textura digital canvas, please get in touch with us today via our support form
                        </p>
                        <button className="homeMainButton">
                            <a className={'homeMainButtonLnk homeOverV'} href={"/digital-canvas"}>
                                SUPPORT FORM
                            </a>
                        </button>
                    </div>
                </Row>
            </Content>
        </Layout>
    </div>)
}

export default HelpOnlineStore;