import React from 'react';
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'

const Research = (props) => {
    return (
        <Layout>
            <Content></Content>
        </Layout>
    )
}

export default Research;
