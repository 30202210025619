import React, {useState, useRef, useEffect} from 'react';
import {Spring} from 'react-spring/renderprops';
import titleImg from "./img/art_title.png"
import "./ArtPage.css"
import {Tabs} from "antd";

import a20 from "../../img/Artists/19 - Alexander Rhys.webp";
import a18 from "../../img/Artists/18 - Ethan Platt.webp";
import a19 from "../../img/Artists/17 - Matthew Stickland.webp";
import a17 from "../../img/Artists/Angelique Cover Image.webp";
import a15 from "../../img/Artists/16 - Caitlin Flood-Molyneux.webp";
import a1 from "../../img/Artists/1 - Jonathan Armour.webp";
import a6 from "../../img/Artists/BarbaraSafrandeNiverville/Autumn Equinox_square.webp";
import a11 from "../../img/Artists/11 - Richard Battye.webp";
import a7 from "../../img/Artists/Christian Redermaye.webp";
import a3 from "../../img/Artists/3 - Lucy Nelson.webp";
import a14 from "../../img/Artists/15 - Rory Watson.webp";
import a16 from "../../img/Artists/14 - Ant Webb.webp";
import a4 from "../../img/Artists/4 - Jo Vincent.webp";
import a12 from "../../img/Artists/12 - Guille Giagante.webp";
import a5 from "../../img/Artists/5 - Jenette Coldrick.webp";
import a2 from "../../img/Artists/2 - Ke Yang.webp";
import a10 from "../../img/Artists/10 - David Bickley.webp";
import a8 from "../../img/Artists/8 - Doris Luming.webp";
import a9 from "../../img/Artists/9 - Linwang Wang.webp";

import ben from "./img/Rectangle 7194.png"

import g1 from "./img/g1.png"
import g2 from "./img/g2.png"
import g3 from "./img/g3.png"
import g4 from "./img/g4.png"
import g5 from "./img/g5.png"
import g6 from "./img/g6.png"
import l1 from "./img/l1.png"
import l2 from "./img/l2.png"
import l3 from "./img/l3.png"
import l4 from "./img/l4.png"
import l5 from "./img/l5.png"
import l6 from "./img/l6.png"

function ArtPage() {
    // artists
    const myRef = useRef(null);

    const ArtistsList = [
        {'name': 'Alexander Rhys', 'img': a20, 'path': '/artist/alexanderrhys'},
        {'name': 'Ethan Platt', 'img': a18, 'path': '/artist/ethanplatt'},
        {'name': 'Matthew Stickland', 'img': a19, 'path': '/artist/matthewstickland'},
        {'name': 'Angelique Nagovskaya', 'img': a17, 'path': '/artist/angeliquenagovskaya'},
        {'name': 'Caitlin Flood-Molyneux', 'img': a15, 'path': '/artist/caitlinflood'},
        {'name': 'Jonathan Armour', 'img': a1, 'path': '/artist/jonathanarmour'},
        {'name': 'Barbara Safran de Niverville', 'img': a6, 'path': '/artist/barbara'},
        {'name': 'Richard Battye', 'img': a11, 'path': '/artist/richardbattye'},
        {'name': 'Christian Redermayer', 'img': a7, 'path': '/artist/christianredermayer'},
        {'name': 'Lucy Nelson', 'img': a3, 'path': '/artist/lucynelson'},
        {'name': 'Rory Watson', 'img': a14, 'path': '/artist/rorywatson'},
        {'name': 'Anthony Webb', 'img': a16, 'path': '/artist/anthonywebb'},
        {'name': 'Jo Vincent', 'img': a4, 'path': '/artist/jovincent'},
        {'name': 'Guille Giagante', 'img': a12, 'path': '/artist/guillegiagante'},
        {'name': 'Jenette Coldrick', 'img': a5, 'path': '/artist/jenettecoldrick'},
        {'name': 'Ke Yang', 'img': a2, 'path': '/artist/keyang'},
        {'name': 'David Bickley', 'img': a10, 'path': '/artist/davidbickley'},
        {'name': 'Doris Luming', 'img': a8, 'path': '/artist/dorisluming'},
        {'name': 'Linwang Wang', 'img': a9, 'path': '/artist/linwangwang'},
    ]

    const filters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    const sortedArtists = ArtistsList.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    const [artists, setArtists] = useState(sortedArtists);
    const [filter, setFilter] = useState(null);

    function filterArtistsByFirstLetter(n) {
        if (filter === n) {
            setFilter('')
            setArtists(ArtistsList.filter(artist => artist.name.startsWith('')));
        } else {
            setFilter(n);
            setArtists(ArtistsList.filter(artist => artist.name.startsWith(n)));
        }
        if (myRef.current) {
            myRef.current.scrollIntoView({behavior: 'instant'});
        }
    }

    // galleries
    const galleryList = [
        {
            'preview': g2,
            'logo': l2,
            'description': 'The National Galleries of Scotland collection showcases well-known ' +
                'and hidden gems from Scotland\'s internationally leading art collection. Discover and admire masterpieces ' +
                'from legendary artists such as Leonardo da Vinci, Sandro Botticelli, and Thomas Gainsborough, or get to know ' +
                'the extraordinary personalities depicted in paintings by John Singer Sargent and Sir Henry Raeburn. ' +
                'If you are a fan of vivid colours, you can lose yourself in the works of Samuel John Peploe and Edouard Vuillard. ' +
                'No matter what your preference, there is something for everyone in this world-class art collection'
        },
        {
            'preview': g3,
            'logo': l3,
            'description': 'The Estorick Collection of Modern Italian Art opened in London in 1998. ' +
                'A Grade II listed Georgian town house, it was originally restored with support from the Heritage Lottery Fund. ' +
                'Further recent renovations have opened up the space of the ground-floor entranceway, bookshop and café, ' +
                'while its six galleries and art libraryhave also been fully upgraded. The Collection is known internationally for its core of  Futurist works,' +
                ' as well as figurative art and sculpture dating from 1890 to the 1950s. The exhibition programme continues to address ' +
                'artists, movements, and questions in ways  that change our understanding of Italian art and culture.'
        },
        {
            'preview': g1,
            'logo': l1,
            'description': 'The Royal Birmingham Society of Artists ( RBSA) is an artist-led' +
                ' charity in Birmingham, UK. Over the two hundred years of its existence, it has built up an archive of ' +
                'work created by its many illustrious artist members.'
        },
        {
            'preview': g4,
            'logo': l4,
            'description': 'The Art Institute of Chicago is a cultural landmark renowned for ' +
                'its world-class collection and commitment to artistic excellence. With its rich and diverse collection, ' +
                'the Art Institute of Chicago continues to inspire and  engage art enthusiasts from around the world.'
        },
        {
            'preview': g5,
            'logo': l5,
            'description': 'The National Gallery of Art in Washington, D. C. is a renowned ' +
                'institution that showcases an impressive collection of artistic masterpieces spanning centuries. ' +
                'With its commitment to fostering cultural appreciation and education, the National Gallery of Art is a ' +
                'leading international institution.'
        },
        {
            'preview': g6, 'logo': l6, 'description': 'The Rijksmuseum is the national museum of the Netherlands. ' +
                'It houses world-famous  masterpieces from the Dutch Golden Age, including Vermeer\'s "The Milkmaid" ' +
                'and  Rembrandt\'s "Night Watch". The museum itself is also a masterpiece,  with its stunning building ' +
                'and remarkable interior design. In 80 galleries,  8,000 objects tell the story of 800 years of Dutch history.'
        }
    ]

    const [isSticky, setIsSticky] = useState(false);
    const firstGalleryRef = useRef(null);
    const secondGalleryRef = useRef(null);
    const thirdGalleryRef = useRef(null);
    const fourthGalleryRef = useRef(null);
    const fifthGalleryRef = useRef(null);
    const sixthGalleryRef = useRef(null);
    const galleryRefs = [firstGalleryRef, secondGalleryRef, thirdGalleryRef, fourthGalleryRef, fifthGalleryRef, sixthGalleryRef]
    const [distance, setDistance] = useState(0);

    const handleScroll = () => {
        const rootFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
        if (firstGalleryRef.current) {
            const rect2 = secondGalleryRef.current.getBoundingClientRect();

            const rect3 = thirdGalleryRef.current.getBoundingClientRect();

            const rect4 = fourthGalleryRef.current.getBoundingClientRect();

            const rect5 = fifthGalleryRef.current.getBoundingClientRect();

            const rect6 = sixthGalleryRef.current.getBoundingClientRect();

            const rect = firstGalleryRef.current.getBoundingClientRect();

            const offset = rect6.top - rect.top

            const threshold = 6 * rootFontSize;
            const effectiveArea = 30 * rootFontSize
            const passed = rect.toString()
            if (rect3.top > 0) {
                setDistance(0)
                setIsSticky(rect.top < threshold)
                console.log(rect.top + ":" + threshold)
            } else {
                setDistance(Math.round(offset))
                console.log("distance: " + distance)
                setIsSticky(rect6.top > threshold)
            }

            const changeSrcOffset = -window.innerHeight * 0.5;
            let imgSrc = g2
            // const changeSrcOffset = -300
            if (rect6.top + changeSrcOffset < 0) {
                imgSrc = g6
                // changeImage(g6)
            } else if (rect5.top + changeSrcOffset < 0) {
                imgSrc = g5
                // changeImage(g5)
            } else if (rect4.top + changeSrcOffset < 0) {
                imgSrc = g4
                // changeImage(g4)
            } else if (rect3.top + changeSrcOffset < 0) {
                imgSrc = g1
                // changeImage(g1)
            } else if (rect2.top + changeSrcOffset < 0) {
                imgSrc = g3
                // changeImage(g3)
            } else {
                imgSrc = g2
                // changeImage(g2)
            }
            console.log("target " + imgSrc + " current: " + imageSrc)
            setNextImageSrc(imgSrc)

            console.log("imgSrc: " + imgSrc + " imageSrc: " + imageSrc)
        }
    };

    const [imageSrc, setImageSrc] = useState(g2);
    const [fade, setFade] = useState(false);
    const [nextImageSrc, setNextImageSrc] = useState(g2);
    useEffect(() => {
        if (nextImageSrc && nextImageSrc !== imageSrc) {
            setFade(true); // Trigger fade-out

            const timeoutId = setTimeout(() => {
                setImageSrc(nextImageSrc); // Change the image source after fading out
                setFade(false); // Trigger fade-in
            }, 250);

            return () => clearTimeout(timeoutId); // Clean up the timeout if component unmounts
        }
    }, [nextImageSrc, imageSrc]);

    // const changeImage = (newSrc) => {
    //     console.log("new:" + newSrc);
    //     console.log("current: " + imageSrc)
    //
    //     setFade(true);  // Trigger fade-out
    //
    //     setTimeout(() => {
    //         setImageSrc(newSrc);
    //         setFade(false); // Trigger fade-in
    //     }, 500); // The timeout duration should match the fade-out duration in CSS
    // };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{backgroundColor: '#F0F0F0', minWidth: '1040px'}}>
            <div style={{height: '100vh', width: '100%', display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '50%', height: '100%'}}>
                    <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={titleImg}/>
                </div>
                <div style={{width: '50%', height: '100%', backgroundColor: '#CFCFCF'}}>
                    <div style={{
                        paddingLeft: '4vw',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <p className={'art-title-subtitle'}>VIEUNITE FEATURED ARTIST</p>
                        <p className={'art-title-main'}>Rory Watson</p>
                        <button className="homeMainButton black-border-hover-white"
                                style={{width: "27rem", minWidth: "200px"}}>
                            <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                LEARN MORE
                            </p>
                        </button>
                    </div>
                </div>
            </div>
            <div style={{padding: '4rem 8vw'}} className={'art-tab-container'}>
                <Tabs centered defaultActiveKey="1">
                    <Tabs.TabPane tab="ARTISTS" key="1">
                        <div
                            style={{display: 'flex', flexDirection: 'column', marginTop: '50px', alignItems: 'center'}}>
                            <p className={'art-card-title'}>Discover new <span
                                className={'art-card-title-bold'}>Artists.</span></p>
                            <p className={'art-card-body'}>Explore the expansive Vieunite art library and find images to
                                inspire you. From classic
                                artwork to contemporary photography, the Vieunite collection celebrates artistic
                                expression
                                and offers users a wide variety of work to be displayed. From photos that capture a
                                moment
                                in time to moving artwork that brings peace to your space, Vieunite has got it all!
                                Fresh
                                work is constantly added to the library for you to choose from, so you are sure to find
                                something you truly love.
                            </p>
                            <div style={{height: '30px'}}></div>
                            <button className="homeMainButton black-border-hover-white"
                                    style={{width: "27rem", minWidth: "200px"}}>
                                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                    ARTIST BROCHURE
                                </p>
                            </button>
                        </div>
                        <div ref={myRef} id='artists' style={{height: '40px'}}></div>
                        <div style={{width: '100%', padding: '40px'}}>
                            <p style={{width: '100%', textAlign: 'center'}}>
                                {filters.map((item, idx) => {
                                    return (
                                        <span
                                            className={filter === item ? 'art-filter-alphabet-selected' : 'art-filter-alphabet'}
                                            onClick={() => filterArtistsByFirstLetter(item)}>{item}</span>
                                    )
                                })}
                            </p>
                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: "wrap",
                            flexDirection: "row",
                            padding: '30px 0'
                        }}>
                            {artists.map((item, idx) => {
                                return (
                                    <div style={{width: '25%', padding: '0.8vw'}}>
                                        <div style={{width: '100%', borderRadius: '15px', overflow: 'hidden'}}>
                                            <img src={item.img}
                                                 className={'art-artist-avatar'}></img>
                                        </div>
                                        <p className={'art-artist-name-label'}>{item.name}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="GALLERIES" key="2">
                        <div
                            style={{display: 'flex', flexDirection: 'column', marginTop: '50px', alignItems: 'center'}}>
                            <p className={'art-card-title'}>Connecting You to <span
                                className={'art-card-title-bold'}>Galleries.</span></p>
                            <p className={'art-card-body'}>Explore the expansive Vieunite art library and find images to
                                inspire you. From classic artwork to contemporary photography, the Vieunite collection
                                celebrates artistic expression and offers users a wide variety of work to be displayed.
                                From photos that capture a moment in time to moving artwork that brings peace to your
                                space,
                                Vieunite has got it all! Fresh work is constantly added to the library for you to choose
                                from,
                                so you are sure to find something you truly love.
                            </p>
                        </div>
                        <div style={{height: '1.5rem'}}></div>

                        {galleryList.map((item, idx) => {
                            return (
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '10vh 0',
                                    minHeight: '80vh'
                                }}>
                                    <div ref={galleryRefs[idx]} style={{minWidth: '40vw', minHeight: '40vw'}}>
                                        {idx === 0 &&
                                            // <Spring
                                            //     from={{opacity: 0}}
                                            //     to={{opacity: 1}}
                                            //     reset
                                            //     onRest={() => {
                                            //         // This changes the src after the fade-out is complete
                                            //         setSrc(toggle ? 'https://via.placeholder.com/300' : 'https://via.placeholder.com/150');
                                            //     }}
                                            // >
                                            //     {props => (
                                            //         <img src={imgSrc} style={{
                                            //             width: '40vw',
                                            //             borderRadius: '15px',
                                            //             position: isSticky ? 'fixed' : 'relative',
                                            //             top: isSticky ? '6rem' : 0,
                                            //             left: isSticky ? '9.6vw' : 0
                                            //         }}></img>
                                            //     )}
                                            // </Spring>

                                            <img alt="Switchable"
                                                 className={`art-page-fade ${fade ? 'art-page-fade-out' : 'art-page-fade-in'}`}
                                                 src={imageSrc}
                                                 style={{
                                                     width: '40vw',
                                                     borderRadius: '15px',
                                                     position: isSticky ? 'fixed' : 'relative',
                                                     top: isSticky ? '6rem' : `${distance}px`,
                                                     // left: isSticky ? '9.6vw' : 0
                                                 }}></img>
                                        }
                                    </div>
                                    <div className={'spacer'}/>
                                    <div style={{maxWidth: '40vw'}}>
                                        <img src={item.logo}></img>
                                        <div style={{height: '35px'}}></div>
                                        <p className={'art-curation-body-label'}>{item.description}
                                        </p>
                                        <button className="homeMainButton black-border-hover-white"
                                                style={{width: "100%"}}>
                                            <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                                VIEW COLLECTION
                                            </p>
                                        </button>
                                        <div style={{height: '10px'}}></div>
                                        <button className="homeMainButton black-border-hover-white"
                                                style={{width: "100%"}}>
                                            <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                                VISIT GALLERY WEBSITE
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            )
                        })}

                    </Tabs.TabPane>
                    <Tabs.TabPane tab="CURATION TEAM" key="3">
                        <div
                            style={{display: 'flex', flexDirection: 'column', marginTop: '50px', alignItems: 'center'}}>
                            <p className={'art-card-title'}>Art Tastemakers of the <span
                                className={'art-card-title-bold'}>Highest Order.</span></p>
                            <p className={'art-card-body'}>Explore the expansive Vieunite art library and find images to
                                inspire you. From classic artwork to contemporary photography, the Vieunite collection
                                celebrates artistic expression and offers users a wide variety of work to be displayed.
                                From photos that capture a moment in time to moving artwork that brings peace to your
                                space,
                                Vieunite has got it all! Fresh work is constantly added to the library for you to choose
                                from,
                                so you are sure to find something you truly love.
                            </p>
                            <div style={{height: '100px'}}></div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <img src={ben}></img>
                            <div style={{maxWidth: '45vw', padding: '3.5rem'}}>
                                <p className={'art-curation-title-label'}>Dr. Benedict Carpenter Van Barthold</p>
                                <p className={'art-curation-body-label'}>Phasellus ac convallis velit. Donec non urna
                                    velit. Ut interdum porttitor felis eget bibendum.
                                    Curabitur varius ultricies consequat. Duis quis fermentum lectus, ut sagittis purus.
                                    Cras dictum, justo nec iaculis ornare, leo massa vestibulum metus, et semper nulla
                                    mi vel nunc.
                                    Donec vitae est gravida, interdum velit quis, fringilla diam. Interdum et malesuada
                                    fames ac ante ipsum primis in faucibus.
                                </p>
                            </div>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>

            <div style={{height: '80px'}}></div>
        </div>
    )
}

export default ArtPage;
