import React from 'react';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {left_text, NEW_HOME_TEXT, right_text} from "./text";
import video_1 from './images/Homepage Graphic 01 (1).mp4'
import img_1 from './images/Full Control.webp'
import img_2 from './images/Accessing Quality Artwork.webp'
import img_3 from './images/Become a Reseller.webp'
import m_video from './images/Thumbnail for mobile homepage.mp4'
import m_img_1 from './images/m-pro-home-1.png'
import m_img_2 from './images/m-pro-home-2.png'
import m_img_3 from './images/m-pro-home-3.png'
import m_img_4 from './images/m-pro-home-4.png'
import './style.scss'
import head_video from './images/Home Header Video BG_13 U3.mp4'
import m_head_video from './images/Mobile Home Header Video BG_13 (1).mp4'
import {useNavigate} from "react-router-dom";
import {ProHomeMeta} from "./meta";
import useWindowResize from "../../apps/Hooks/useWindowResize";

const makeMuted = (elt) => {
    if (elt) {
        elt.muted = true;
    }
};

function ProFullScreenBgVideo(props) {

    return (
        <section className="pro-fullScreenVideoSection">
            <div className="videoOverlay m-no"></div>
            {/* Overlay added here */}
            <video className="fullScreenVideo" playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                <source src={props.desktopUrl} type={'video/webm'}></source>
            </video>
            <video className="fullScreenVideoMobile" playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                <source src={props.mobileUrl}></source>
            </video>
        </section>
    );
}

const ProHome = (props) => {
    const navigate = useNavigate()
    const {windowWidth} = useWindowResize()
    return (
        <div>
            <ProHomeMeta/>
            <Layout>
                <Content>
                    {/*<Row justify={"center"}>*/}
                    <div className="image-overlay-container" style={{
                        margin: '4vh 0 0 0'
                    }}>
                        <ProFullScreenBgVideo
                            mobileUrl={m_head_video}
                            desktopUrl={head_video}
                        />
                        <div className="overlay-content m-no">
                            <p className={'pro-h1'}>Transform your business with Vieunite Pro,a revolutionary
                                new art and communication platform</p>
                            {/*<p className={'pro-h2'}></p>*/}
                            <p className="overlay-subtext">
                                Combining innovative and unique display technology with a back-end management system and
                                a
                                huge library of expertly curated artwork.
                            </p>
                            <Button className="learn-more-button pro-btn-hover-to-white"
                                    onClick={() =>
                                        window.location.href = '/business/textura-pro'
                                    }>
                                Learn more
                            </Button>
                        </div>
                        {windowWidth <= 600 && <div className="m-overlay-content m-yes">
                            <p className={'m-pro-h1 m-yes'}>Transform your business with Vieunite Pro </p>
                            <p className="m-overlay-subtext m-yes">
                                Combining innovative and unique display technology with a back-end management system and
                                a
                                huge library of expertly curated artwork.
                            </p>
                            <Button className="m-pro-home-btn"
                                    onClick={() =>
                                        window.location.href = '/business/textura-pro'
                                    }>
                                Learn more
                            </Button>
                        </div>}
                    </div>
                    {/*</Row>*/}
                    <Row justify={"center"} style={{
                        columnGap: '4rem',
                        margin: '6vh 0 5vh 0'
                    }}
                         className={'m-no'}
                    >
                        {NEW_HOME_TEXT.map((t, idx) =>
                            <p key={idx} className={'pro-home-txt-new'} style={idx !== 0 ? {width: '60vw'} : {}}>
                                {t}
                            </p>)}
                    </Row>
                    <Row justify={"center"} className={'j-section m-no'}>
                        <video className={'j-img'} playsInline loop autoPlay ref={makeMuted} muted rel={"preload"} style={{
                            borderRadius:'15px',
                        }}>
                            <source src={video_1} type={'video/webm'}/>
                        </video>
                        <div className="j-text-overlay">
                            <p className={'j-h1'} style={{marginTop: '0'}}>Revolutionary Digital Art Canvas</p>
                            {/*<div className="j-white-line"></div>*/}
                            <p className={'j-txt'}>
                                The Textura Pro digital canvas uses cutting-edge technology to faithfully display
                                surface
                                textures and enhance artwork details. Featuring anti-glare surface treatment and a
                                finely
                                calibrated backlight, the vivid high-definition display delivers incredibly lifelike
                                artwork, truly bringing it to life.
                            </p>
                            <Button
                                onClick={_ =>
                                    window.location.href = '/business/textura-pro'}
                                className="j-overlay-button pro-btn-hover-to-white ">
                                Learn more
                            </Button>
                        </div>
                    </Row>
                    <Row justify={"center"} className={'j-section m-no'}>
                        <img src={img_1} className={'j-img'} style={{}}></img>
                        <div className="j-text-overlay">
                            <p className={'j-h1'}>Full Control of Management</p>
                            {/*<div className="j-white-line"></div>*/}
                            <p className={'j-txt'}>
                                With the Vieunite Pro Portal, you can easily create captivating visual experiences.
                                Remotely
                                manage your network of your Textura Pros; create playlists mixed with your own content,
                                schedule content to play at different times and access all of your data for monitoring
                                and
                                analytics.
                            </p>
                            <Button
                                onClick={_ => {
                                    if (localStorage.getItem('token')) {
                                        window.location.href = '/proportal/'
                                    } else {
                                        navigate('/business/prosignin', {state: {nextPage: '/proportal/'}})
                                    }
                                }}
                                className="j-overlay-button pro-btn-hover-to-white ">
                                Learn more
                            </Button>
                        </div>
                    </Row>
                    <Row justify={"center"} className={'j-section m-no'}>
                        <img src={img_2} className={'j-img'}></img>
                        <div className="j-text-overlay">
                            <p className={'j-h1'}>Accessing Quality Curated Artwork</p>
                            {/*<div className="j-white-line"></div>*/}
                            <p className={'j-txt'}>
                                We have developed partnerships with some of the world’s most renowned galleries and
                                celebrated artists to build an enormous art library. Our expert curation team has worked
                                diligently to group artworks into playlists to suit any industry sector, mood, themes or
                                even colour schemes.
                            </p>
                            <Button
                                onClick={_ =>
                                    window.location.href = '/business/curated-collections'}
                                className="j-overlay-button pro-btn-hover-to-white ">
                                Discover more
                            </Button>
                        </div>
                    </Row>
                    <Row justify={"center"} className={'j-section m-no'}>
                        <img src={img_3} className={'j-img'}></img>
                        <div className="j-text-overlay">
                            <p className={'j-h1'}>Become a Reseller Partner</p>
                            {/*<div className="j-white-line"></div>*/}
                            <p className={'j-txt'}>
                                Discover the benefits of becoming a reseller for Vieunite Pro. Introduce your customers
                                to
                                experience a revolutionary new display medium and lead the way in visual storytelling.
                                Partner with Vieunite Pro to access competitive pricing, marketing support and exclusive
                                product offerings.
                            </p>
                            <Row>
                                <Button
                                    onClick={_ =>
                                        window.location = "mailto:support@vieunite.com"}
                                    className="j-overlay-button pro-btn-hover-to-white "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                         fill="none">
                                        <path
                                            d="M19.8335 23.9166H8.16683C4.66683 23.9166 2.3335 22.1666 2.3335 18.0833V9.91659C2.3335 5.83325 4.66683 4.08325 8.16683 4.08325H19.8335C23.3335 4.08325 25.6668 5.83325 25.6668 9.91659V18.0833C25.6668 22.1666 23.3335 23.9166 19.8335 23.9166Z"
                                            stroke="white" stroke-width="2" stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path
                                            d="M19.8332 10.5L16.1815 13.4167C14.9798 14.3733 13.0082 14.3733 11.8065 13.4167L8.1665 10.5"
                                            stroke="white" stroke-width="2" stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                    Email us
                                </Button>
                                <Button
                                    className="j-overlay-button pro-btn-hover-to-white "
                                    style={{marginLeft: '2vw', width: '12vw'}}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 26 26"
                                         fill="none">
                                        <path
                                            d="M25.6318 21.3849C25.6318 21.8049 25.5385 22.2366 25.3402 22.6566C25.1418 23.0766 24.8852 23.4733 24.5468 23.8466C23.9752 24.4766 23.3452 24.9316 22.6335 25.2233C21.9335 25.5149 21.1752 25.6666 20.3585 25.6666C19.1685 25.6666 17.8968 25.3866 16.5552 24.8149C15.2135 24.2433 13.8718 23.4733 12.5418 22.5049C11.2002 21.5249 9.92849 20.4399 8.71516 19.2383C7.5135 18.0249 6.4285 16.7533 5.46016 15.4233C4.5035 14.0933 3.7335 12.7633 3.1735 11.4449C2.6135 10.1149 2.3335 8.84325 2.3335 7.62992C2.3335 6.83659 2.4735 6.07825 2.7535 5.37825C3.0335 4.66659 3.47683 4.01325 4.09516 3.42992C4.84183 2.69492 5.6585 2.33325 6.52183 2.33325C6.8485 2.33325 7.17516 2.40325 7.46683 2.54325C7.77016 2.68325 8.0385 2.89325 8.2485 3.19659L10.9552 7.01159C11.1652 7.30325 11.3168 7.57159 11.4218 7.82825C11.5268 8.07325 11.5852 8.31825 11.5852 8.53992C11.5852 8.81992 11.5035 9.09992 11.3402 9.36825C11.1885 9.63659 10.9668 9.91659 10.6868 10.1966L9.80016 11.1183C9.67183 11.2466 9.61349 11.3983 9.61349 11.5849C9.61349 11.6783 9.62516 11.7599 9.6485 11.8533C9.6835 11.9466 9.7185 12.0166 9.74183 12.0866C9.95183 12.4716 10.3135 12.9733 10.8268 13.5799C11.3518 14.1866 11.9118 14.8049 12.5185 15.4233C13.1485 16.0416 13.7552 16.6133 14.3735 17.1383C14.9802 17.6516 15.4818 18.0016 15.8785 18.2116C15.9368 18.2349 16.0068 18.2699 16.0885 18.3049C16.1818 18.3399 16.2752 18.3516 16.3802 18.3516C16.5785 18.3516 16.7302 18.2816 16.8585 18.1533L17.7452 17.2783C18.0368 16.9866 18.3168 16.7649 18.5852 16.6249C18.8535 16.4616 19.1218 16.3799 19.4135 16.3799C19.6352 16.3799 19.8685 16.4266 20.1252 16.5316C20.3818 16.6366 20.6502 16.7883 20.9418 16.9866L24.8035 19.7283C25.1068 19.9383 25.3168 20.1833 25.4452 20.4749C25.5618 20.7666 25.6318 21.0583 25.6318 21.3849Z"
                                            stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                                    </svg>
                                    03455 481311
                                </Button>
                            </Row>

                        </div>
                    </Row>
                    {windowWidth <= 600 && <Row justify={"center"} className={'m-yes'} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <p className={'m-pro-home-txt'} style={{marginTop: '4vh'}}>
                            Vieunite Pro turns casual visitors into loyal patrons by creating a dynamic environment that
                            captivates and inspires your audience.
                        </p>
                        <p className={'m-pro-home-txt'} style={{margin: '4vh auto'}}>
                            By combining the Textura Pro digital canvas with our Curated Collections, you can subtly
                            deliver promotional content while strengthening your brand identity and setting yourself
                            apart from competitors.
                        </p>
                        <p className={'m-pro-home-txt'} style={{marginBottom: '4vh'}}>
                            Plus, Vieunite Pro provides a new and stimulating method that boosts employee morale,
                            fostering a positive and productive work environment.
                        </p>
                        {/*<img src={m_img_1} className={'m-j-img'}/>*/}
                        <video className={'j-img'} playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}
                               style={{
                                   borderRadius: '15px',
                               }}>
                            <source src={m_video} type={'video/webm'}/>
                        </video>
                        <p className={'m-pro-home-title'}>
                            Revolutionary Digital Art Canvas
                        </p>
                        <p className={'m-pro-home-txt'} style={{marginBottom: '4vh'}}>
                            The Textura Pro digital canvas uses cutting-edge technology to perfectly display surface
                            textures and enhance artwork details. The anti-glare surface treatment and calibrated
                            backlight deliver vivid, lifelike artwork, truly bringing it to life.
                        </p>
                        <Button className={'m-pro-home-btn'} onClick={() => {
                            window.location.href = '/business/textura-pro'
                        }}>Learn more</Button>
                        <img src={m_img_2}/>
                        <p className={'m-pro-home-title'}>
                            Full Control of Management
                        </p>
                        <p className={'m-pro-home-txt'} style={{marginBottom: '4vh'}}>
                            Easily create captivating experiences with the Vieunite Pro Portal. Remotely manage your
                            Textura Pro network, create unique playlists, schedule content, and access all important
                            data.
                        </p>
                        <Button className={'m-pro-home-btn'} onClick={() => {
                            window.location.href = '/business/explore'
                        }}>Learn more</Button>
                        <img src={m_img_3}/>
                        <p className={'m-pro-home-title'}>
                            Accessing Quality Curated Artwork
                        </p>
                        <p className={'m-pro-home-txt'} style={{marginBottom: '4vh'}}>
                            We’ve partnered with world renowned galleries and artists to create an extensive art
                            library. Our expert curation team has worked diligently to organise these artworks into
                            playlists that suit any industry, mood, theme, or colour scheme.
                        </p>
                        <Button className={'m-pro-home-btn'} onClick={() => {
                            window.location.href = '/proportal/discover-art'
                        }}>Learn more</Button>
                        <img src={m_img_4}/>
                        <p className={'m-pro-home-title'}>
                            Become a Reseller Partner
                        </p>
                        <p className={'m-pro-home-txt'} style={{marginBottom: '4vh'}}>
                            Discover the benefits of becoming a Vieunite Pro reseller. Introduce your customers to a
                            revolutionary display medium and lead the way in visual storytelling. Gain access to
                            competitive pricing, marketing support and exclusive offerings.
                        </p>
                        <Button className={'m-pro-home-btn'} onClick={() => {
                            window.location.href = '/business/textura-pro'
                        }}>Contact us</Button>
                    </Row>}
                </Content>
            </Layout></div>
    )
}

export default ProHome;
