import React from 'react';
import {Button, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import image1 from '../img/image - bio.png'


const txt = [
    'This Knowledge Transfer Partnership (KTP) with NTU aims to create the Vieunite Integrated Therapeutic Digital Display System, incorporating sensor-integrated displays and an AI-powered content engine. The project explores how positive (salutogenetic) and biophilic (nature-inspired) theories can be utilised in digital display systems to enhance the health benefits of connecting people with digital nature representations. These sophisticated displays will offer therapeutic content influenced by biophilic theory, individual circadian rhythms, mood variations, and indoor environmental conditions. The primary focus is to boost the health and well-being of occupants by generating personalised salutogenetic biophilic digital content through precise information exchange between customized control and analytics algorithms.',
    'This KTP will also aid Allsee in developing Vieunite, a new digital platform for artists to showcase their work directly to potential clients by aligning the biophilic attributes of the art with the users\' biophilic preferences. Vieunite will make biophilic art easily accessible to the public, promoting overall health and well-being.',
    'The innovation leverages recent research in biophilic theory and smart building technologies. The importance of digital displays in smart, healthy buildings is often overlooked, and this KTP project aims to fill this gap by developing comprehensive Digital Therapeutic Display Systems that integrate hardware, software, and content seamlessly.',
    'The first research paper, titled: \'Exploring Machine Learning Applications for Biophilic Art Displays to Promote Health and Well-being\' , Link was presented at the PETRA’24 (PErvasive Technologies Related to Assistive Environments) conference. It lays out the framework for our study, where we developed a novel algorithm that classifies and recommends biophilic artwork based on emotional responses of users. Our initial feedback indicates a positive reception thus paving the way for further research in using nature-inspired art as a therapeutic prescription to alleviate negative emotions and promote positivity.  Our second research paper titled \'A Deep Learning Method for Classification of Biophilic Artworks\', Link focusses on using state-of-the-art artificial intelligence algorithms for categorising artworks based on biophilic features and curating a diverse collection of Biophilic artworks. Our techniques have the potential to help artists, curators, and researchers studying to interpret and exploit the effects of mental well-being on exposure to nature-inspired visual aesthetics but also enables a methodical exploration of the study of Biophilia and Biophilic artwork for aesthetic preferences.',
    'Currently we are exploiting Machine Learning Techniques to build a system capable of recognizing emotions from artworks. Such a system would help discern emotions associated with art and can not only offer deeper cognizance for artists and researchers but also help in creating well-curated collections. Our work also presents potential applications in building advanced recommendation systems using emotional elements.'
]
const Biophilia = (props) => {
    return (
        <Content style={{
            margin: '0 0 0 0',
            background: '#F0F0F0', padding: '100px 0'
        }}>
            <p className={'research-demo-p1'}>
                Advanced Therapeutic Digital Display System incorporating Biophilia
            </p>
            <p className={'research-demo-p2'}>
                Creating AI-driven displays that enhance well-being through personalised, nature-inspired content.
            </p>
            <img className={'research-demo-img'} alt={'bio'} src={image1}/>
            {txt.map((t) => {
                return (<p className={'research-demo-p3'}>
                    {t}
                </p>)
            })}
            <Row justify={"center"} style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "flex-start",
                background: '#DFDFDF',
                paddingBottom:'40px',
                marginTop:'80px'
            }}>
                <div style={{width: '80vw', margin: "auto"}}>
                    <p className={'research-demo-paper-p1'}>
                        Downloads
                    </p>
                    <p className={'research-demo-paper-p2'}>
                        Exploring Machine Learning Applications for Biophilic Art Displays to Promote Health and
                        Well-being
                    </p>
                    <p className={'research-demo-paper-p3'}>
                        Authors: Purna Kar, Jordan J. Bird, Yangang Xing, Alexander Sumich, Andrew Knight, Ahmad Lotfi,
                        Benedict Carpenter van Barthold
                    </p>
                    <p className={'research-demo-paper-p4'}>
                        June 2024
                    </p>
                    <Button className={'research-demo-btn'}>
                        READ PAPER
                    </Button>
                </div>
                <div style={{width: '80vw', margin: "auto"}}>
                    <p className={'research-demo-paper-p2'}>
                        A Deep Learning Method for Classification of Biophilic Artworks
                    </p>
                    <p className={'research-demo-paper-p3'}>
                        Authors: Purna Kar, Jordan J. Bird, Yangang Xing, Alexander Sumich, Andrew Knight, Ahmad Lotfi,
                        Benedict Carpenter van Barthold
                    </p>
                    <p className={'research-demo-paper-p4'}>
                        March 2024
                    </p>
                    <Button className={'research-demo-btn'}>
                        READ PAPER
                    </Button>
                </div>
            </Row>
            <div className={'research-demo-final-div'}>
                <p className={'research-demo-final-div-p1'}>Authors</p>
                <p className={'research-demo-final-div-p2'}>Purna Kar, Jordan J. Bird, Yangang Xing, Alexander Sumich,
                    Andrew Knight, Ahmad Lotfi, Benedict Carpenter van Barthold</p>
                <p className={'research-demo-final-div-p1'} style={{marginTop:'6vh'}}>Acknowledgments</p>
                <p className={'research-demo-final-div-p2'}>
                    Alex Iftimie, Arka Dhar, Audrey Cerles, Ben Newhouse, Boris Petrov, Collin Burns, David Robinson,
                    Greg Brockman, Hannah Wong, Jan Leike, Jason Kwon, Justin Wang, Karthik Rangarajan, Kayla Wood,
                    Kelly Kim, Kendra Rimbach, Kevin Button, Laurel MacMillan, Leopold Aschenbrenner, Lindsey Held, Lucy
                    Chen, Mario Saltarelli, Miles Brundage, Natalie Kim, Niko Felix, Noam Brown, Rahul Arora, Ryan
                    Biddy, Ryan Ragona, Sarah Shoker, Shaun VanWeelden, Steph Lin, Tiffany Citra, Yonadav Shavit
                </p>
                {/*<p className={'research-demo-final-div-p1'}>Footnotes</p>*/}
            </div>
        </Content>
    )
}

export default Biophilia;
