import React, {useRef} from 'react';
import {Link, redirect, useNavigate} from 'react-router-dom';
import {Layout, Col, Row, Card, Space, Button} from 'antd';
import '../../App.css';
import "../Textura/Textura.css"
import "./HomePage.scss";
import "./HomePageJack.scss";
import {Content} from "antd/es/layout/layout";
import {DiscoverText, HOME_TEXT, TEXTURA_TEXT} from "../../text.js";
import landscape_vd from "../../img/HomePage/1 - Header Video/Vieunite Launch - Homepage - Landscape 2.mp4";
import section2_img from "./img/desktop-album-evergreen-1x 1.png"
import section3_1_img from "./img/Group 1904.png"
import section3_2_img from "./img/Mask group.png"
import vertical_vd from "../../img/HomePage/1 - Header Video/3334ce07-9121-4893-ba16-c3d5db436bc3.mp4";
import {HomepageMeta} from "../../components/PageMeta/HomepageMeta";
import NTULogo from "../../img/HomePage/9 - Logos/ntu.svg"
import UKTILogo from "../../img/HomePage/9 - Logos/innovate - 2024.svg"
import RBSALogo from "../../img/HomePage/9 - Logos/rbsa.svg"
import RoyalLogo from "../../img/HomePage/9 - Logos/the royal society.svg"
import section1_pic from "./img/section_1.png"
import homeExample1 from "./img/homeExample1.png"
import homeExample2 from "./img/homeExample2.png"
import homeExample3 from "./img/homeExample3.png"
import homeExample4 from "./img/homeExample4.png"
import homeExample5 from "./img/homeExample5.png"
import homeExample6 from "./img/homeExample6.png"
import leftScroll from "./img/LeftScroll.png"
import rightScroll from "./img/RightScroll.png"
import scrollActive from "./img/ScrollActive.png"
import scrollInactive from "./img/ScrollInactive.png"


import { useState } from 'react';

const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '38px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const Img_Radius = {
    borderRadius: '0.625rem',
}



function HomePage() {
    // For SSG through "react-snap", can't assign the "mute" property directly on <video> tags.
    // So using the following function to do it
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };

    const navigate = useNavigate();

    const [activeDirection, setActiveDirection] = useState('right'); // 'left', 'right', or 'none'

    const images = [
        { src: homeExample1, alt: 'RBSA Gallery | Birmingham' },
        { src: homeExample2, alt: 'Selfridges | London' },
        { src: homeExample3, alt: 'Estorick Gallery | London' },
        { src: homeExample4, alt: 'Printemps | Paris' },
        { src: homeExample5, alt: 'KaDeWe | Berlin' },
        { src: homeExample6, alt: 'La Rinascente | Rome' }
    ];

    const [index, setIndex] = useState(0);
    const [activeLineIndex, setActiveLineIndex] = useState(0);
    const sliderRef = useRef(null); // 创建ref
    const itemsPerPage = 3;
    const totalLines = 6; // 固定显示6个横线
    const ScrollActive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );

    const ScrollInactive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="#717171" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );

    const smoothSlide = (direction) => {
        const container = sliderRef.current;
        const containerWidth = container.offsetWidth; // 获取容器宽度
        const currentTransform = container.style.transform || 'translateX(0px)';
        const currentTranslateX = parseInt(currentTransform.split('(')[1]) || 0;

        let newTranslateX;
        if (direction === 'right') {
            newTranslateX = currentTranslateX - containerWidth; // 向右移动
        } else if (direction === 'left') {
            newTranslateX = currentTranslateX + containerWidth; // 向左移动
        }

        // 应用平滑动画效果
        container.style.transition = 'transform 0.5s ease';
        container.style.transform = `translateX(${newTranslateX}px)`;
    };


    const slideRight = () => {
        const newIndex = (index + 1) % images.length;
        setIndex(newIndex);
        setActiveLineIndex(prevLineIndex => (prevLineIndex + 1) % totalLines);
    };

    const slideLeft = () => {
        const newIndex = (index - 1 + images.length) % images.length;
        setIndex(newIndex);
        setActiveLineIndex(prevLineIndex => (prevLineIndex - 1 + totalLines) % totalLines);
    };

    const updateActiveLine = (newIndex) => {
        const lineIndex = Math.floor(newIndex / itemsPerPage) % totalLines;
        setActiveLineIndex(lineIndex);
    };


    return (
        <div>
            <HomepageMeta></HomepageMeta>
            <Layout>
                <Content>
                    <div className="homeVideoContainer">
                        <video playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                            <source src={landscape_vd} type={'video/webm'}></source>
                        </video>
                    </div>
                    <div className="homeVideoTextContainer">
                        <p>Bringing art to life with the<br/>Textura Canvas</p>
                        <button className="homeMainButton" style={{width: "20vw", minWidth: "200px"}}>
                            <a className={'homeMainButtonLnk homeOverV'} href={"/digital-canvas"}>
                                LEARN MORE
                            </a>
                        </button>
                    </div>
                    <Content style={{width: '100%', backgroundColor: '#f4f4f4'}}>
                        {/*What is Vieunite*/}
                        <Row>
                            <p className="homeDescriptionText">
                                {HOME_TEXT["Home Section title"]}
                            </p>
                        </Row>
                        <Row className="homeSection1">
                            <Col span={24} lg={12} style={{padding: "5%"}}>
                                <div className="section1TextContainer">
                                    <p className="homeSection1TitleText">Texture Accuracy Excellence.</p>
                                    <p className="homeSection1BodyText">{HOME_TEXT["Home Section 1"]}</p>
                                    <button className="homeSection1Button">
                                        <a className={'homeSection1ButtonLnk homeOverV'} href={"/digital-canvas"}>
                                            LEARN MORE
                                        </a>
                                    </button>
                                </div>
                            </Col>

                            <Col span={24} lg={12}>
                                <img src={section1_pic} alt={'preview'}
                                     style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                            </Col>
                        </Row>

                        <Row className="homeSection2">
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Simplicity</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>Textura was
                                        designed
                                        with user accessibility in mind
                                        and offers a simple setup and install process that requires little to no
                                        technical
                                        knowledge.</p>
                                </div>
                            </Col>
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Accuracy</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>The advanced
                                        texture-accurate display technology perfectly replicates artistic finishes such
                                        as
                                        brush strokes and preserves them for a lifelike aesthetic.</p>
                                </div>
                            </Col>
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Efficiency</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>Schedule times for
                                        the
                                        digital canvas to turn on and off automatically, only using power when needed
                                        and
                                        improving the energy efficiency.</p>
                                </div>
                            </Col>
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Transparency</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>There are no
                                        subscription costs associated with the Textura digital canvas or the Vieunite
                                        app
                                        for the entire lifetime of the product.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="homeSection3Row">
                            <div className="homeSection2Background">
                                <div
                                    className="homeSection3TextWidth"
                                    style={{
                                        height: "100%",
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: 'column-reverse',
                                        color: "white",
                                        padding: "2.5rem"
                                    }}>
                                    <button className="homeMainButton" style={{width: "100%"}}>
                                        <a className={'homeMainButtonLnk homeOverV'} href={"/digital-canvas"}>
                                            LEARN MORE
                                        </a>
                                    </button>
                                    <p className="homeSection1BodyText">{HOME_TEXT["Home Section 2"]}</p>
                                    <p className="homeSection1TitleText">Huge collection of exceptional artwork.</p>
                                    <div className="homeSection3Height"></div>
                                </div>
                            </div>
                        </Row>

                        <Row style={{margin: '0 9vw 1rem 9wv'}}>
                            <h2 className="title-logo" style={{
                                marginTop: '4rem',
                                fontSize: '1.5rem',
                                lineHeight: '36px',
                                width: '100%',
                                textAlign: 'center'
                            }}
                            >
                                IN PARTNERSHIP WITH
                            </h2>
                        </Row>

                        <Row style={{margin: '2rem 9vw 6rem 9vw'}}>
                            <Col
                                span={12}
                                lg={6}
                                style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img onClick={() => {
                                    window.open("https://www.ukri.org/councils/innovate-uk/")
                                }}
                                     alt={'logo'} className={'logo-img'} src={UKTILogo}></img>
                            </Col>
                            <Col
                                span={12}
                                lg={6}
                                style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img onClick={() => {
                                    window.open("https://rbsa.org.uk/")
                                }}
                                     alt={'logo'} className={'logo-img'} src={RBSALogo}></img>
                            </Col>
                            <Col
                                span={12}
                                lg={6}
                                style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img onClick={() => {
                                    window.open("https://www.ntu.ac.uk/study-and-courses/academic-schools/art-and-design")
                                }}
                                     alt={'logo'} className={'logo-img'} src={NTULogo}></img>
                            </Col>
                            <Col
                                span={12}
                                lg={6}
                                style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img onClick={() => {
                                    window.open("https://royalsociety.org/")
                                }}
                                     alt={'logo'} className={'logo-img'} src={RoyalLogo}></img>
                            </Col>
                        </Row>

                        <Row style={{margin: '0 6vw 0 6vw'}}>
                            <Col span={24} md={12} style={{padding: "0 3vw 3rem 3vw"}}>
                                <img src={section3_1_img} alt={'preview'} style={{width: "100%", paddingBottom: "20px"}}/>
                                <p className="homeSection1TitleText">Access Curated Art and Artists.</p>
                                <p className="homeSection1BodyText">{HOME_TEXT["Home Section 4_1"]}</p>
                            </Col>
                            <Col span={24} md={12} style={{padding: "0 3vw 3rem 3vw"}}>
                                <img src={section3_2_img} alt={'preview'} style={{width: "100%", paddingBottom: "20px"}}/>
                                <p className="homeSection1TitleText">Display Personal Photos and Videos.</p>
                                <p className="homeSection1BodyText">{HOME_TEXT["Home Section 4_2"]}</p>
                            </Col>
                        </Row>

                        <Row>
                            <div className="homeTexturaExampleContainer homeTexturaExampleContainer-jack">
                                <p className="homeSection1TitleText" style={{color: "white", paddingBottom: "2vw"}}>
                                    See Textura in Person
                                </p>

                                <div ref={sliderRef} style={{
                                    display: 'flex',
                                    overflow: 'hidden',
                                    width: '100%',
                                    transition: 'scrollLeft 0.5s ease'
                                }}>
                                    {Array.from({length: itemsPerPage}).map((_, idx) => {
                                        const imageIndex = (index + idx) % images.length;
                                        const image = images[imageIndex];
                                        return (
                                            <div key={imageIndex} className="example-section-image-container"
                                                 style={{flex: '1 0 auto', margin: '0 5px'}}>
                                                <img src={image.src} alt={image.alt}
                                                     className="example-section-image example-section-image-jack"/>
                                                <div className="example-section-overlay">
                                                    <div className="example-section-text">{image.alt}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '20px'
                                }}>
                                    <img src={leftScroll} alt="Scroll Left" onClick={slideLeft}
                                         style={{cursor: 'pointer'}}/>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {Array.from({length: totalLines}).map((_, idx) => (
                                            <div key={idx} style={{margin: '0 2px'}}>
                                                {activeLineIndex === idx ? <ScrollActive/> : <ScrollInactive/>}
                                            </div>
                                        ))}
                                    </div>
                                    <img src={rightScroll} alt="Scroll Right" onClick={slideRight}
                                         style={{cursor: 'pointer'}}/>
                                </div>

                            </div>
                        </Row>

                        <Row>
                            <div className="homeSupportTextContainer">
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Get Support</p>
                                <p className="homeSupportBody">If you have questions about the Textura digital canvas or
                                    Vieunite app that aren't
                                    answered here, or if you encounter any issues with your product, explore our
                                    dedicated support resources or reach out to us for assistance.</p>
                                <button className="homeSupportButton">
                                    <a className={'homeSupportButtonLnk homeOverV'} href={"/digital-canvas"}>
                                        SUPPORT
                                    </a>
                                </button>
                            </div>
                        </Row>
                    </Content>
                </Content>
            </Layout></div>
    );
}

export default HomePage;