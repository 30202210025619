import React from 'react';
import {Col, Row} from 'antd';
import './PageFooter.css';
import {ReactComponent as TwitterIcon} from "../../img/SVG/Vieunite Social Media Icons_Twitter.svg";
import {ReactComponent as InstagramIcon} from "../../img/SVG/Vieunite Social Media Icons_Instagram.svg";
import {ReactComponent as FacebookIcon} from "../../img/SVG/Vieunite Social Media Icons_Facebook.svg";
import {ReactComponent as LinkedinIcon} from "../../img/SVG/Vieunite Social Media Icons_Linkedin.svg";
import {ReactComponent as YouTubeIcon} from "../../img/SVG/Vieunite Social Media Icons_Youtube.svg";
import {ReactComponent as PinterestIcon} from "../../img/SVG/Vieunite Social Media Icons_Pinterest.svg";
import {ReactComponent as EmailIcon} from "../../img/SVG/email.svg";
import {ReactComponent as PhoneIcon} from "../../img/SVG/phone-call.svg";
const getLastDayOfMonth = () => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const day = lastDay.getDate();
    const suffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    const formattedDate = `${day}${suffix(day)} ${lastDay.toLocaleDateString('en-GB', { month: 'long', year: 'numeric' })}`;
    return formattedDate;
}
const ContactRow = () => {
    return (
        <div>
            <Row
                justify={"center"}
                style={{
                    backgroundColor: '#fff',
                    fontFamily: 'Proxima-nova bold',
                    color: '#000',
                    paddingTop: '1.3em',
                    paddingBottom: '1.3em',
                    // borderTop:'1px solid #a1a1a1',
                }}
            >
                <Col className={'primary-font footer-contact'} span={8} lg={8} xs={20}
                     style={{display: "flex", justifyContent: "flex-start"}}>
                    <p className={'primary-font'} style={{
                        fontSize: '18px',
                        fontWeight: '600',
                    }}>
                        Contact Vieunite
                    </p>
                </Col>
                <Col className={'footer-icon-text'} span={12} lg={12} xs={20}>
                    <a href="mailto:info@vieunite.com" style={{color: '#000', display: "flex", columnGap: '2px'}}>
                        <EmailIcon/>
                        <span className={'primary-font'} style={{marginTop: '1.5px'}}>EMAIL VIEUNITE</span>
                    </a>
                    <div style={{display: "flex", columnGap: '1px'}}>
                        <PhoneIcon/>
                        <span className={'primary-font blk'} style={{marginTop: '1.5px', color: "black"}}>
                            <a style={{color: "black"}} href="tel:03455-481311">{'03455-481311'}</a>
                        </span>
                    </div>
                </Col>
            </Row>
            {window.location.href.endsWith('digital-canvas') || window.location.href.endsWith('digital-canvas/')?
                <Row style={{
                    backgroundColor: '#fff'
                }}>
                    <p style={{
                        fontWeight: '500',
                        color: '#000',
                        fontSize: '0.8vw',
                        textAlign: "right",
                        margin: '0 0 0 63%',
                    }}>
                        *Free UK and international shipping on any orders placed before {getLastDayOfMonth()}.
                    </p>
                </Row>
                :<>
                </>}
        </div>
    )
}
export const PortalFooter = () =>
    <Row justify={"center"} style={{backgroundColor: '#828282',}}>
        <Col lg={8} xs={22} style={{display: "flex"}}>
            <div style={{width: '100%'}}>
                <Row style={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 1fr 1fr',
                    justifyItems: 'center',
                    padding: '1rem',
                }}>
                    <div style={{color: 'white', fontSize: '0.8rem', fontWeight: '600'}}>
                        &copy;2023 Vieunite Limited All Rights Reserved.
                    </div>
                    <div style={{fontSize: '0.8rem', fontWeight: '600'}}>
                        <a href={"/terms-and-conditions"} style={{color: 'white'}} className={"footer-text"}>
                            Terms and Conditions
                        </a>
                    </div>
                    <div style={{fontSize: '0.8rem', fontWeight: '600'}}>
                        <a href={"/end-user-agreement"} style={{color: 'white'}} className={"footer-text"}>
                            End User Agreement
                        </a>
                    </div>
                </Row>
            </div>
        </Col>
    </Row>
export const BottomRow = () => {
    return (
        <Row justify={"center"}>
            <Col lg={20} xs={22} style={{display: "flex"}}>
                <div className={"desktop-end"} style={{width: '100%'}}>
                <Row>
                        <hr style={{backgroundColor: "white", width: '100%'}}></hr>
                    </Row>
                    <Row style={{
                        display: 'grid',
                        gridTemplateColumns: '10fr 1fr 1fr 1fr',
                        justifyItems: 'center',
                        // paddingBottom: '40px'
                    }}>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: "start"}}>
                            &copy;2023 Vieunite Limited All Rights Reserved.
                        </div>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: 'center'}}>
                            <a href={"/terms-and-conditions"} style={{color: '#777777'}} className={"footer-text"}>
                                Terms&Conditions
                            </a>
                        </div>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: 'center'}}>
                            <a href={"/end-user-agreement"} style={{color: '#777777'}} className={"footer-text"}>
                                End User Agreement
                            </a>
                        </div>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: 'end'}}>
                            <a href={"/ArtistsTermsAndConditions"} style={{color: '#777777'}} className={"footer-text"}>
                                Artist's Terms&Conditions
                            </a>
                        </div>
                    </Row>
                    <Row style={{
                        display: 'flex',
                        justifyItems: 'center',
                        paddingBottom: '40px',
                    }}>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: "start"}}>
                        VAT Registration No. 411723238
                        </div>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: "start"}}>
                            &nbsp; &nbsp; &nbsp; Company Registration No. 13883746
                        </div>
                    </Row>
                </div>

                <div className={"mobile-end"}>
                    <hr style={{backgroundColor: "white",}}></hr>
                    <Row>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <div style={{color: 'gray', fontSize: '14px'}}>
                                <a href={"/terms-and-conditions"} style={{color: 'grey'}} className={"footer-text"}>
                                    Terms and Conditions&nbsp;
                                </a>
                            </div>
                            <div style={{color: 'grey', fontSize: '14px',}}>
                                <a href={"/end-user-agreement"} style={{color: 'gray'}} className={"footer-text"}>
                                    &nbsp;&nbsp;&nbsp;End User Agreement
                                </a>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <p style={{color: 'grey', fontSize: '14px',}}>
                            &copy;2023 Vieunite Limited. All Rights Reserved.
                        </p>
                    </Row>
                    <Row style={{
                        display: 'flex',
                    }}>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: "start"}}>
                            VAT Registration No. 411723238
                        </div>
                    </Row>
                    <Row style={{
                        display: 'flex',
                        paddingBottom: '40px',
                    }}>
                        <div style={{color: '#777777', fontSize: '10px', justifySelf: "start"}}>
                            Company Registration No. 13883746
                        </div>
                    </Row>

                </div>
            </Col>
        </Row>
    )
}

const BasicFooter = () => {
    return (
        <>
            <Row justify={"center"} style={{paddingTop: '40px'}}>
                <Col lg={{span: 20, offset: 2}} xs={20} style={{display: "flex"}}>
                    <div className="footer-text-container">
                        <p style={{textAlign: 'center'}}>
                            <a href="/digital-canvas" style={{color: '#777777'}}
                               className={"footer-text"}>
                                Textura
                            </a>
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <a href="/artists" style={{color: '#777777'}}
                               className={"footer-text"}>
                                Vieunite Artists
                            </a>
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <a href="/news" style={{color: '#777777'}}
                               className={"footer-text"}>
                                News
                            </a>
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <a href="/contact" style={{color: '#777777'}}
                               className={"footer-text"}>
                                Contact Us
                            </a>
                        </p>
                        <p style={{textAlign: 'center'}}
                           onClick={() => {
                               if ((/Android/i.test(navigator.userAgent))) {
                                   window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB';
                               }
                               if (/iPad|iPhone|iPod|Mac/i.test(navigator.userAgent)) {
                                   window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065';
                               } else {
                                   window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065';
                               }
                           }}>
                            <a style={{color: '#777777'}}
                               className={"footer-text"}>
                                Vieunite App
                            </a>
                        </p>
                    </div>
                </Col>
                <Col flex={11} style={{display: "flex", justifyContent: "flex-end"}}></Col>
            </Row>

            {/*Social Media Icons Row*/}
            <Row style={{justifyContent: 'center'}}>
                <ul className={'menu menu-desktop'} style={{marginBottom: '5px'}}>
                    <li>
                        <a className={'social-icon-btn'}
                           href={"https://www.instagram.com/vieunite/?hl=en"}
                           style={{marginLeft: '-10px'}}
                        >
                            <InstagramIcon/>
                        </a>
                    </li>
                    <li>
                        <a className={'social-icon-btn'} href={"https://www.pinterest.co.uk/vieunite/"}
                           style={{marginLeft: '0.7rem'}}>
                            <PinterestIcon/>
                        </a>
                    </li>
                    <li>
                        <a className={'social-icon-btn'} href={"https://twitter.com/VieuniteHQ"}
                           style={{marginLeft: '0.7rem'}}>
                            <TwitterIcon/>
                        </a>
                    </li>
                    <li>
                        <a className={'social-icon-btn'}
                           href={"https://www.facebook.com/Vieunite-106534841663393"}
                           style={{marginLeft: '0.7rem'}}
                        >
                            <FacebookIcon/>
                        </a>
                    </li>
                    <li>
                        <a className={'social-icon-btn'}
                           href={"https://www.linkedin.com/company/vieunite/"}
                           style={{marginLeft: '0.7rem'}}>
                            <LinkedinIcon/>
                        </a>
                    </li>
                    <li>
                        <a className={'social-icon-btn'}
                           href={"https://www.youtube.com/channel/UClpqq46UCV1Ojkfef0HijFQ"}
                           style={{marginLeft: '0.7rem'}}>
                            <YouTubeIcon/>
                        </a>
                    </li>
                </ul>
            </Row>
            <BottomRow></BottomRow>
        </>
    )
}

const PageFooter = () => {
    return (
        <div className={'v-footer'}>
            <ContactRow></ContactRow>
            <BasicFooter></BasicFooter>
        </div>
    )
}

export const NoContactFooter = () => {
    return (
        <div className={'v-footer'} style={{borderTop: 'none'}}>
            <BasicFooter></BasicFooter>
        </div>
    )
}

export default PageFooter;
