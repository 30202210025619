import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import {get_api, post_api, post_api_upload} from "../../../serverTemp";
import SparkMD5 from 'spark-md5';
import backButton from "../imgs/Back button.svg";
import assign from "./imgs/assign.svg";
import {useNavigate} from "react-router-dom";
import { Modal, Input } from 'antd';


const MainContent = ({onUserUpdate}) => {
    const [user, setUser] = useState({});
    const [newData, setNewData] = useState({
        id: localStorage.getItem('user_id'),
        avatar: '',
        email: '',
        nickname: '',
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tempNickname, setTempNickname] = useState('');

    useEffect(() => {
        get_api('/user/detail').then(res => {
            console.log(res)
            setUser(res);
            setNewData({
                ...newData,
                avatar: res.avatar,
                email: res.email,
                nickname: res.nickname
            });
        });
    }, []);
    const showModal = () => {
        setTempNickname(newData.nickname);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSave = () => {
        setIsModalVisible(false);
        setNewData({ ...newData, nickname: tempNickname });
        // Here you would also call an API to update the server
    };

    const calculateFileMD5 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = e.target.result;
                const md5 = SparkMD5.ArrayBuffer.hash(data);
                resolve(md5);
            };
            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const handleFileChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const fileMD5 = await calculateFileMD5(file);

            post_api_upload(file.name, fileMD5, file)
                .then((url) => {
                    console.log("Response URL:", url);
                    setSelectedImage(URL.createObjectURL(file));
                    setNewData({...newData, avatar: url});
                    setUser(user => ({...user, avatar: url})); // 更新用户状态中的 avatar
                    // 自动保存更改
                    saveChanges(url);
                })
                .catch(error => {
                    console.error("Error uploading file:", error);
                });
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const getSubscriptionType = (subscription) => {
        const types = ["Standard", "Advanced", "Premium"];
        return types[subscription] || "Unknown";
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setNewData(prevData => ({
            ...prevData,
            [name]: value
        }));
        console.log('Updated newData:', newData);
    };

    const saveChanges = async (avatarUrl) => {
        const updatedData = {
            ...newData,
            avatar: avatarUrl
        };

        console.log('Saving new data:', updatedData);
        post_api('/user/update', updatedData)  // 确保这个API能更新用户头像的URL
            .then(res => {
                console.log('Update response:', res);
                // 重新获取用户数据以确保前端显示的是最新信息
                get_api('/user/detail').then(res => {
                    console.log('New user data:', res);
                    setUser(res);
                    if (onUserUpdate) {
                        onUserUpdate(res); // 调用父组件的回调函数，更新用户状态
                    }
                });
            })
            .catch(error => {
                console.error('Error while updating:', error);
            });
    };

    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        navigate('/proportal/MyAccount');
    };
    const handleChangePasswordClick = () => {
        navigate('/business/ProForgotPassword');
    };
    const handleDevicesAllignStart = () => {
        navigate('/proportal/AssignDevices');
    };


    return (
        <Row gutter={16}>
            <Col span={12}>
                <p className='PortalAccountDetails-title'>Current details</p>
                <div className={'account-profile-container'}>
                    <div className={'account-pt-profile-div-new'}>
                        {user.avatar ?
                            <img className={'account-pt-profile-div-img-new'}
                                 src={user?.avatar}
                                 style={{cursor: 'pointer'}}
                                 alt={'avatar'}
                            /> :
                            <div className={'account-pt-profile-div-avatar-out-new'}>
                                <div className={'account-pt-profile-div-avatar-new'}>
                                    {user?.nickname ? user.nickname[0].toUpperCase() : ''}
                                </div>
                            </div>
                        }
                    </div>
                    <Button className="profile-avatar-button" onClick={triggerFileInput}>Change avatar</Button>
                    <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleFileChange}/>
                </div>

                <div>
                    <div className="MD-form-group1">
                        <label className='MD-form-group-label'>Account details</label>
                        <div className="account-details-content">
                            <div className='inner-content'> {/* 新添加的内层容器 */}
                                <div className='AccountDetailsName'>{newData.nickname}</div>
                                <Button className='ChangeAccountDetailsButton' onClick={showModal}>Change account details</Button>
                                <Modal title="Change Account Details" visible={isModalVisible} onOk={handleSave} onCancel={handleCancel}>
                                    <Input value={tempNickname} onChange={(e) => setTempNickname(e.target.value)} placeholder="Enter new nickname" />
                                </Modal>
                            </div>
                        </div>
                        <div className="horizontal-line"></div>
                    </div>

                    <div className="MD-form-group2">
                        <label className='MD-form-group-label'>Email address</label>
                        <div className="account-details-content">
                            <div className='AccountDetailsName'>{user.email}</div>
                        </div>
                        <div className="horizontal-line"></div>
                    </div>

                    <div className="MD-form-group3">
                        <label className='MD-form-group-label'>Subscription type</label>
                        <div className="account-details-content">
                            <div className='inner-content-subscription'>
                                <div className='AccountDetailsName'>{getSubscriptionType(user.subscription)}</div>
                                <Button className='ChangeAccountDetailsButton' onClick={handleUpgradeClick}>Upgrade subscription</Button>
                            </div>
                        </div>
                        <div className="horizontal-line"></div>
                    </div>

                    <div className="MD-form-group4">
                        <label className='MD-form-group-label'>Current password</label>
                        <div className="account-details-content">
                            <div className='inner-content-password'>
                                <div className='AccountDetailsName'>*********</div>
                                <Button className='ChangeAccountDetailsButton' onClick={handleChangePasswordClick}>Change password</Button>
                            </div>
                        </div>
                        <div className="horizontal-line"></div>
                    </div>
                </div>

                <div className="assign-devices-container">
                    <img src={assign} alt="assign" className='assignlogo'/>
                    <div className='assign-devices'>
                        <div className='assign-devices-title'>Assign Devices</div>
                        <div className="assign-devices-text">
                            Allocate Textura digital canvases from your account to other accounts.
                        </div>
                        <Button className='Assign-devices-button' onClick={handleDevicesAllignStart}>Start</Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

const ChangeAccountDetails = () => {
    const [user, setUser] = useState({});

    const handleUserUpdate = (updatedUser) => {
        setUser(updatedUser);
    };

    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res);
        });
    }, []);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };

    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <div className='ProAccountDetails'>
                                <div className='header3'>
                                    <img src={backButton} alt="BackButton" className='BackButtonIcon8'
                                         style={{marginRight: '0.5rem', cursor: "pointer"}} onClick={handleBackClick}/>
                                    <p className='ProAccountDetails-title'>Hello {user?.nickname}</p>
                                </div>
                                <MainContent onUserUpdate={handleUserUpdate}/>
                            </div>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    );
}

export default ChangeAccountDetails;